import LoadingLogo from "./logo_white.png";
import "./callback.css";

export default function Callback() {

    return (
        <div className={"callback"}>
            <div className={"callback__logo-col"}>
                <img src={LoadingLogo} className={"callback__logo"} />
                <h3 className={"callback__text"}>You will be redirected...</h3>
            </div>
        </div>
    );
}