import LoginImgMobile from "./background_mobile.png";
import LoginImgLarge from "./background_large.png";
import EntryForm from "../../components/form/EntryForm";
import "./entry.css";

export default function Entry() {

  return (
    <main className={"entry"}>
      <img className={"entry__background"} src={LoginImgMobile} />
      <img className={"entry__background--large"} src={LoginImgLarge} />
      <div className={"entry__column-spacer"} />
      <div className={"entry__form-row"}>
        <div id={"entry__row-spacer--left"} className={"entry__row-spacer"} />
        <EntryForm />
        <div className={"entry__row-spacer"} />
      </div>
      <div className={"entry__column-spacer"} />
    </main>
  );
}
