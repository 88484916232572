import { useAuth0 } from "@auth0/auth0-react";
import "./login.css";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={"login"}>
      <div className={"login__text"}>
        <p className={"login__description-text"}>Start Learning Your Way</p>
      </div>
      <div className={"login__button-row"}>
        <div className={"login__button-column"}>
          <button
            className={"login__button"}
            onClick={() => void loginWithRedirect({
              appState: {
                returnTo: "/dashboard",
              },
            })}
          >
            Log In
          </button>
          <button
            className={"login__signup-button"}
            onClick={() =>
              void loginWithRedirect({
                authorizationParams: { screen_hint: "signup" },
                appState: {
                  returnTo: "/verification",
                },
              })
            }
          >
            Sign Up
          </button>
          <p className={"login__trial-description"}>
            Start with Lognitiv 14 days free of charge!
          </p>
        </div>
      </div>
    </div>
  );
}
