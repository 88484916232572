import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }: {children: any}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    console.log("NAVIGATE");
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="login.lognitiv.com"
      clientId="mYKVbc4F76xrF7R4UvE3fcNa137kWIpz"
      authorizationParams={{
        audience: "https://platform.lognitiv.com/api/v1",
        redirect_uri: "https://platform.lognitiv.com/callback",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};