import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VerifyMailImg from "./verify_mail.svg";
import Logo from "./logo_white.png";
import "./verification.css";


export default function Verification() {
    const { user } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user["verified"]) {
            navigate("/dashboard");
        }
    });

    if (user && !user["verified"]) {
        return (
            <div className={"verification"}>
                <div className={"verification__top-spacer"} />
                <img src={Logo} className={"verification__logo"} />
                <img src={VerifyMailImg} className={"verification__mail-icon"} />
                <div className={"verification__middle-spacer"} />
                <div className={"verification__text-col"}>
                    <h1>Please confirm your email to continue...</h1>
                    <h3>We have sent you an e-mail to confirm that your e-mail address is correct.</h3>
                    <h3>Please verify this by clicking on the link in the email.</h3>
                    <button>Resend confirmation email</button>
                </div>
            </div>
        );
    } else {
        // TODO: Design Error page
        return (<div>Error</div>)
    }
}