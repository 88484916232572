import PaymentOption, { PaymentOptionDefinition } from "./option/PaymentOption";
import BackImgSmall from "./back_small.png";
import BackImgLarge from "./back_large.png";
import PaymentLogo from "./login_logo_payment.png";
import "./payment.css";

export default function Payment() {
  const monthlyPayment: PaymentOptionDefinition = {
    title: "Monthly",
    price: "$9.99/month",
    detailTitle: "Start your 14-day free trial",
    details: [],
  };

  const annualPayment: PaymentOptionDefinition = {
    title: "Annual",
    price: "$99.99/year",
    detailTitle: "Start your 14-day free trial",
    details: [],
  };

  return (
    <section className={"payment"}>
      <img src={BackImgSmall} className={"payment__back"} />
      <img src={BackImgLarge} className={"payment__back-large"} />
      <img src={PaymentLogo} className={"payment__header-logo"} />
      <h1 className={"payment__header-title"}>
        Boost Your Academic Performance
      </h1>
      <h4 className={"payment__header-description"}>
        Start your free trial today and choose your subscription
      </h4>
      <h4 className={"payment__header-description--mobile"}>
        Start your free trial
      </h4>
      <h4 className={"payment__header-description--mobile"}>&</h4>
      <h4 className={"payment__header-description--mobile"}>choose your subscription</h4>
      <div className={"payment__option-row"}>
        <PaymentOption
          product="lognitiv-monthly-subscription"
          optionDefinition={monthlyPayment}
        />
        <PaymentOption
          product="annually-subscription"
          optionDefinition={annualPayment}
        />
      </div>
    </section>
  );
}
