/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAuth0 } from "@auth0/auth0-react";
import "./option.css";


export type PaymentOptionDefinition = {
  title: string;
  price: string;
  detailTitle: string;
  details: string[];
};

interface PaymentOptionProps {
  product: string;
  optionDefinition: PaymentOptionDefinition;
}

export default function PaymentOption(props: PaymentOptionProps) {
  const { user } = useAuth0();

  if (user) {
    function storeCheckout() {
      window.fastspring.builder.push({
        reset: true,
        products: [
          {
            path: props.product,
            quantity: 1,
          },
        ],
        tags: {
          userId: user?.sub || "ERROR"
        },
        checkout: true,
      });
    }

    return (
      <div className={"option"}>
        <p className={"option__title"}>{props.optionDefinition.title}</p>
        <p className={"option__price"}>{props.optionDefinition.price}</p>
        <div className={"option__button-row"}>
          <button className={"option__submit-button"} onClick={storeCheckout}>
            Start your free trial
          </button>
        </div>
        <div className={"option__description-row"}>
          <ul className={"option__description"}>
            <li>
              {props.optionDefinition.detailTitle}
              <ul>
                {props.optionDefinition.details.map((detailText) => (
                  <li>{detailText}</li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return <div>Error</div>;
  }
}
