import { FC, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Payment from "./Payment";

interface PaymentGuardProps {
  component: FC;
}

type LockstatusResponse = {
  locked: boolean;
}

export default function PaymentGuard({ component }: PaymentGuardProps) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [locked, setLocked] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

    if (user && user["verified"]) {
      const getLockedStatus = async () => {

        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://platform.lognitiv.com/api/v1",
            },
          });

          const userLockStatus = "https://platform.lognitiv.com/api/v1/user/lockstatus/1";

          const metadataResponse = await fetch(userLockStatus, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`,
            },
          });

          const resp: LockstatusResponse = await metadataResponse.json();
          setLocked(resp.locked);
          setIsLoading(false);

        } catch (e) {
          if (e instanceof Error) {
            console.log("ITS AN ERROR in request");
            console.log(e.message);

            setIsLoading(false);
          }
        }
      };

      getLockedStatus().catch((e: unknown) => {
        if (e instanceof Error) {
          console.log("ITS AN ERROR");
          console.log(e);
          setIsLoading(false);
        }
      });
    }


  }, [getAccessTokenSilently]);

  if(!isAuthenticated) {
    return <div>Error</div>
  }else if(isLoading) {
    return <div>Loading...</div>
  }else if(isAuthenticated && !locked) {
    return <>{component}</>
  }else {
    return <Payment />
  }
}
