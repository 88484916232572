import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FC } from "react";


interface AuthenticationGuardProps {
    component: FC;
}


export const AuthenticationGuard = ({ component }: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
        <div>Loading...</div>
      </div>
    ),
  });

  return <Component />;
};