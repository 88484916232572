import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes"
import RouterFallback from "./components/fallback/RouterFallback";

const rootElement = document.getElementById("root");


if(rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <RouterProvider router={router} fallbackElement={<RouterFallback />}/>
    </StrictMode>
  );
}