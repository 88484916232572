import Login from "./login/Login";
import Logo from "./login_logo_white.png";
import "./entry-form.css";


export default function EntryForm() {

    return (
        <section className={"entry-form"}>
            <div className={"entry-form__logo-row"}>
                <img src={Logo} className={"entry-form__logo"} />
            </div>
            <Login />
        </section>
    );
}