import { createBrowserRouter } from "react-router-dom";
import { lazy } from 'react';
import { AuthenticationGuard } from "./components/auth/AuthenticationGuard";
import { Auth0ProviderWithNavigate } from "./components/auth/AuthWithNavigate";
import App from "./App";
import Entry from "./views/entry/Entry";
import NotFound from "./views/error/NotFound";
import Callback from "./components/callback/Callback";
import Verification from "./views/verification/Verification";

const LockedDashboard = lazy(() => import("./views/dashboard/Dashboard"));


export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    ),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Entry />,
      },
      {
        path: "dashboard",
        element: <AuthenticationGuard component={LockedDashboard} />,
      },
      {
        path: "callback",
        element: <Callback />,
      },
      {
        path: "verification",
        element: <Verification />,
      },
    ],
  },
]);
